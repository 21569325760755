import { gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

export const DUPLICATE_PRODUCT_TITLE = (productId: string | null): any => gql`
  query DuplicateProductTitle(
    $merchantId: ID
    $title: String
  ) {
    products(
      first: 1
      where: {
        title: $title
        ${GraphQLHelpers.addFilter(!!productId, 'id_not', `"${productId!}"`)}
        merchant: {
          id: $merchantId
        }
      }
    )
    {
      id
    }
  }
`;

export const COMPONENT_CONFIGS_QUERY = gql`
    query ComponentConfigs {
      componentConfigs(
        first: 500
      ) {
        id,
        title,
        colour { id },
        media {
          src
        }
      }
    }
`;

export const ADDONS_QUERY = gql`
    query Addons {
      addons {
        id,
        title,
        media {
          src
        }
      }
    }
`;

export const COLOURS_QUERY = gql`
    query Colours {
      colours(
        where: {
          flc_id_not: null
        }
      ) {
        id,
        name,
        hex
      }
    }
`;

export const DELIVERY_OPTIONS = gql`
    query DeliveryOptions(
      $merchantId: ID!
    ) {
      deliveryConfigs(where: {
        merchant: {
          id: $merchantId
        }
        deletedAt: null
      }) {
        id
        flc_id
        title
        default
        channel
        region
        deletedAt
        merchant {
          id
          title
          currency
        }
        deliveryCoverage {
          id
          title
          label
        }
        deliveryTiming {
          id
          cutOffHour
          leadtime
          days
          sameDays
        }
        deliveryPrice {
          id
          title
          channel
          sameDayPrice
          nextDayPrice
          perItemDeliveryFee
          isDefault
        }
        holidays {
          id
        }
      }
    }
`;
